const useLoadBananesWorld = () => {
  const data = require
    .context("../../public/img/world", false)
    .keys()
    .map((filePath) => {
      const filename = filePath.split("./")[1]; // "./12-Jade.jpg" => "12-Jade.jpg"
      const filenameWithoutExtension = filename.split(".")[0]; // "12-Jade.jpg" => "12-Jade"
      const [country, title] = filenameWithoutExtension.split("_");

      return {
        filename,
        country,
        title,
      };
    })
    .sort((a, b) => a.order - b.order);

  return data;
};

export default useLoadBananesWorld;
