import BananeImage from "./BananeImage";

const BigBananeImage = ({ name }) => {
  const title = name.replace(/[0-9]/g, ""); // remove number if several photos in the same name, like Montreal2

  return (
    <div style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}>
      <BananeImage name={name} style={{ width: "200", objectFit: "cover" }} />
      <h2
        style={{
          fontSize: 16,
          color: "black",
          backgroundColor: "white",
          textAlign: "center",
          width: "200",
          margin: "auto",
          zIndex: 1000,
        }}
      >
        {title}
      </h2>
    </div>
  );
};

export default BigBananeImage;
