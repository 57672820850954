import React from "react";

const Image = ({ title, imageSrc }) => {
  return (
    <div className="portfolio-item">
      <img src={imageSrc} className="img-responsive" alt={title} />
    </div>
  );
};

export default Image;
