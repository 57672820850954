import Image from "./image";
import React from "react";
import useLoadBananesExamples from "./useLoadBananesExamples";

const BananesExamples = () => {
  const data = useLoadBananesExamples();

  return (
    <div id="bananes" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Bananes</h2>
        </div>
        <div
          className="portfolio-items"
          style={{ marginRight: "10%", marginLeft: "10%" }}
        >
          {data.map(({ filename }) => (
            <div key={filename} className="col-sm-4 col-md-3 col-lg-4">
              <Image
                title={filename}
                imageSrc={require(`../../public/img/bananes/${filename}`)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BananesExamples;
