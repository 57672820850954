import { useCallback, useRef, useState } from "react";
import Map, { Marker } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import Cluster from "./Cluster";
import BigBananeImage from "./BigBananeImage";
import BananeImage from "./BananeImage";
import { markers } from "./markers";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoieGF2aWVydmlsbGVsZWdpZXIiLCJhIjoiY2lxYXlucnlzMDA4NWh4a3E3d2Z2M2NydSJ9.XF9KbUNP1ndO8IlUBlorXQ";
const MAPBOX_STYLE =
  "mapbox://styles/xaviervillelegier/clmuvp31x02nx01qxgnb343yz";

const EdajMarker = ({ marker: { name } }) => {
  const [isExpended, setIsExpended] = useState(false);
  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => setIsExpended((prevIsExpended) => !prevIsExpended)}
    >
      {isExpended ? (
        <BigBananeImage name={name} />
      ) : (
        <BananeImage
          name={name}
          style={{
            objectFit: "cover",
            width: "75px",
            borderRadius: "50%",
            border: "2px solid white",
          }}
        />
      )}
    </div>
  );
};

const EdajMarkerGroup = (props) => {
  const {
    map,
    cluster: {
      properties: { cluster_id, point_count },
    },
    superCluster,
  } = props;
  const elements = superCluster.getLeaves(cluster_id, 4);
  const clusterExpansionZoom = superCluster.getClusterExpansionZoom(cluster_id);
  console.log({ clusterExpansionZoom });

  return (
    <div
      onClick={() => {
        map.easeTo({
          center: props.cluster.geometry.coordinates,
          zoom: clusterExpansionZoom,
        });
      }}
      style={{ position: "relative" }}
    >
      <div
        style={{
          width: "80px",
          height: "80px",
          borderRadius: "50%",
          border: "2px solid white",
          backgroundColor: "white",
          display: "flex",
          flexWrap: "wrap",
          gap: "2px",
          overflow: "hidden",
        }}
      >
        {elements.map(
          ({
            properties: {
              props: { name },
            },
          }) => (
            <BananeImage
              key={name}
              name={name}
              style={{
                minWidth: "0",
                flexBasis: "45%",
                flexGrow: "1",
                objectFit: "cover",
              }}
            />
          )
        )}
      </div>
      <div
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          backgroundColor: "#3c86a3",
          color: "white",
          fontWeight: "bold",
          fontSize: "16px",
          padding: "4px",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {point_count}
      </div>
    </div>
  );
};

const EdajMap = () => {
  const mapRef = useRef(null);
  const mapRefCallback = useCallback((ref) => {
    if (!ref) return;
    mapRef.current = ref;

    const map = ref.getMap();

    const bounds = new mapboxgl.LngLatBounds();
    markers.forEach((city) => {
      bounds.extend(city.coordinates);
    });

    map.fitBounds(bounds, { padding: 80, duration: 500 });
  }, []);
  const [map, setMap] = useState(null);

  return (
    <Map
      ref={mapRefCallback}
      onLoad={() => setMap(mapRef.current.getMap())}
      initialViewState={{
        zoom: 7,
      }}
      projection="mercator"
      style={{ width: "100%", height: "100%" }}
      mapboxAccessToken={MAPBOX_TOKEN}
      mapStyle={MAPBOX_STYLE}
    >
      {map && (
        <Cluster
          map={map}
          radius={40}
          extent={512}
          nodeSize={40}
          element={(clusterProps) => (
            <EdajMarkerGroup {...clusterProps} map={map} />
          )}
        >
          {markers.map((marker) => (
            <Marker
              key={marker.name}
              longitude={marker.coordinates[0]}
              latitude={marker.coordinates[1]}
              name={marker.name}
              anchor="center"
            >
              <EdajMarker marker={marker} />
            </Marker>
          ))}
        </Cluster>
      )}
    </Map>
  );
};

export default EdajMap;
