import React, { useState, useEffect } from "react";
import { Header } from "./components/header";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import WorldMap from "./components/WorldMap";
import BananesExamples from "./components/BananesExamples";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(JsonData);
  }, []);

  return (
    <div>
      <Header data={data.Header} />
      <BananesExamples />
      <div style={{ marginTop: 100 }}>
        <WorldMap />
      </div>
    </div>
  );
};

export default App;
