export const imagePresent = (data, countryMap) =>
  data.find(({ country }) =>
    country?.toLowerCase().includes(countryMap.toLowerCase())
  );

export const imagePresentInCity = (data, cityMap) =>
  data.find(({ title }) =>
    title?.toLowerCase().includes(cityMap.toLowerCase())
  );

export const styleNoImageFound = {
  default: {
    fill: "#D6D6DA",
    outline: "none",
  },
  hover: {
    fill: "#D6D6DA",
    outline: "none",
  },
  pressed: {
    fill: "#D6D6DA",
    outline: "none",
  },
};

export const styleImageFound = {
  default: {
    fill: "#b7baf4",
    outline: "none",
  },
  hover: {
    fill: "#b7baf4",
    outline: "none",
  },
  pressed: {
    fill: "#7887e6",
    outline: "none",
  },
};
