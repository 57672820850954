import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div
        className="intro"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>
                  {props.data ? props.data.paragraph : "Loading"} <br />
                  <br />
                  <a
                    href="https://www.instagram.com/edaj_paris"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="img/instagram-logo.svg"
                      alt="instagram logo"
                      style={{ opacity: 1 }}
                      width="5%"
                      className="wobble-hor-bottom"
                    />
                  </a>
                </p>
                <a
                  href="#bananes"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Voir les bananes
                </a>
                <br />
                <a href="#world" className="btn btn-custom btn-lg page-scroll">
                  Autour du monde
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
