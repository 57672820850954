import { imagePresentInCity } from "./mapHelpers";
import useLoadBananesWorld from "./useLoadBananesWorld";

const BananeImage = ({ name, style }) => {
  const data = useLoadBananesWorld();
  const foundImage = imagePresentInCity(data, name);

  return (
    <img
      style={style}
      src={require(`../../public/img/world/${foundImage.filename}`)}
      alt={foundImage.title}
    />
  );
};

export default BananeImage;
