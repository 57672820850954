const useLoadBananesExamples = () => {
  const data = require
    .context("../../public/img/bananes", false)
    .keys()
    .map((filePath) => {
      const filename = filePath.split("./")[1]; // "./12-Jade.jpg" => "12-Jade.jpg"
      const filenameWithoutExtension = filename.split(".")[0]; // "12-Jade.jpg" => "12-Jade"

      return {
        filename,
        filenameWithoutExtension,
      };
    })
    .sort(
      (a, b) =>
        Number(a.filenameWithoutExtension) - Number(b.filenameWithoutExtension)
    );

  return data;
};

export default useLoadBananesExamples;
