import ReactTooltip from "react-tooltip";
import { useState } from "react";
import EdajMap from "./EdajMap";

const WorldMap = () => {
  const [content, setContent] = useState("");

  return (
    <>
      <div id="world" className="text-center">
        <div className="container">
          {/* <div style={{ marginTop: 0 }}> */}
          <div className="section-title">
            <h2>Autour du monde</h2>
          </div>
          <div style={{ marginRight: "10%", marginLeft: "10%" }}>
            <EdajMap setTooltipContent={setContent} />
            <ReactTooltip backgroundColor="transparent" padding="0">
              {content}
            </ReactTooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorldMap;
